













































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      body: { required },

      image_upload_id: {},
      position: {},
      set: {},
    }
    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
  },
})
export default class OnboardingForm extends Vue {

  public dropFiles = []

  public FILES_HOST = process.env.VUE_APP_UBIC_SERVER_ADDRESS

  public uploadFile(files: File[]) {
    for (const file of files) {
      const formData = new FormData()

      formData.append('file', file)

      // @ts-ignore
      this.$http.post(`/admins/rest/v1/uploads`,
        formData, {
          // emulateJSON: true,
          // headers: {
          //   'Content-Disposition': `form-data; name="photo"; filename="${file.name}"`
          // }
        },
      ).then((response: any) => {
        this.$store.dispatch(`${STORE_KEY}/addAttachments`, response.data.upload)
      })

      // @ts-ignore
      this.$v.form.image_upload_id.$touch()
    }
  }

  get isNew() {
    return this.$route.params.id !== 'new'
  }

  get titleStack() {
    return [
      'Категории продуктов', (this.isNew ? 'Новая категория' : 'Редактирование категории'),
    ]
  }

  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public submitObject() {
    this.$v.form.$touch()

    if (!this.$v.form.$error) {
      this.$store.dispatch(`${STORE_KEY}/saveObject`, this.$route.params.id)
        .then((result) => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)

          this.$router.push({ name: 'settings', params: { active_tab: 'onboarding_list' } })
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: `При сохраненит возникли проблемы. Попробуйте позже.`,
          })
        })
    }
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/initState`, {
      id: this.$route.params.id,
      predefined: this.$route.query,
    })
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
